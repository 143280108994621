<template>
  <b-card>
    <validation-observer
      ref="infoRules"
      v-slot="{ invalid }"
      tag="form"
    >
      <b-row>
        <!-- Server Errors -->
        <b-col md="6">
          <validation-provider
            #default="validationContext"
            rules="required"
            name="certificates"
          >
            <b-form-group
              label="Certificates"
              label-for="certificates"
            >
              <b-form-checkbox-group
                id="certificates_id"
                v-model="engineerForm.certificates"
                class="d-flex flex-column"
                :options="optionsStatusId"
                trim
                placeholder="Certificates"
                :state="getValidationState(validationContext)"
                multiple
              />
              <b-form-invalid-feedback>{{
                validationContext.errors[0]
              }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>

      <b-row>
        <b-col
          v-for="(group, index) in selectedCertificateGroups"
          :key="index"
          md="6"
        >
          <validation-provider
            #default="validationContext"
            rules="required"
            :name="`subCertificates${index}`"
          >
            <b-form-group
              :label="`Sub Certificates for ${group.text}`"
              :label-for="`sub_certificates_${index}`"
            >
              <b-form-checkbox-group
                :id="`sub_certificates_${index}`"
                v-model="engineerForm.subCertificates[group.value]"
                class="d-flex flex-column"
                :options="group.certificates"
                trim
                :state="getValidationState(validationContext)"
                multiple
              />
              <b-form-invalid-feedback>{{
                validationContext.errors[0]
              }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>

      <b-row>
        <b-col
          v-if="Object.values(errors).length > 0"
          cols="12"
        >
          <p
            v-for="(error, key, index) in errors"
            :key="index"
            class="text-danger small"
          >
            {{ error.toString() }}
          </p>
        </b-col>
      </b-row>

      <b-row>
        <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            v-if="!loader && $route.params.id"
            variant="primary"
            class="mr-1"
            :disabled="invalid || Object.values(errors).length > 0"
            @click="addUserSite"
          >
            Save Changes
          </b-button>
          <b-button
            v-if="!loader && !$route.params.id"
            variant="primary"
            class="mr-1"
            :disabled="invalid || Object.values(errors).length > 0"
            @click="addUserSite"
          >
            Add
          </b-button>
          <b-button
            v-if="loader"
            variant="primary"
            disabled
            class="mr-1"
          >
            <b-spinner
              small
              type="grow"
            />
            Loading...
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>
  </b-card>
</template>

<script>
import { ref } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import axios from 'axios'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import decryptData from '@/store/auth/decryptData'

export default {
  data() {
    return {
      required,
      loader: false,
      validationErrors: {},
      errors: {},
      optionsStatusId: [],
      allCertificates: [],
    }
  },

  setup() {
    const { getValidationState } = formValidation()
    const engineerForm = ref({
      name: '',
      email: '',
      certificates: [],
      subCertificates: {},
    })
    return {
      getValidationState,
      engineerForm,
    }
  },

  computed: {
    selectedCertificateGroups() {
      return this.engineerForm.certificates
        .map(cert => {
          const group = this.allCertificates.find(g => g.value === cert)
          if (group) {
            return {
              ...group,
              certificates: group.certificates.map(certif => ({
                value: certif.id,
                text: `${certif.name.ar} - ${certif.name.en}`,
              })),
            }
          }
          return null
        })
        .filter(group => group !== null)
    },
  },

  mounted() {
    this.getEngineerStatus()
  },

  methods: {
    getEngineerStatus() {
      this.loader = true
      axios
        .get('certificate')
        .then(res => {
          this.allCertificates = decryptData(res.data.payload).data.groups.map(item => ({
            value: item.name.ar,
            text: `${item.name.ar} - ${item.name.en}`,
            certificates: item.certificates,
          }))
          this.optionsStatusId = this.allCertificates.map(item => ({
            value: item.value,
            text: item.text,
          }))
        })
        .finally(() => {
          this.loader = false
        })
    },

    addUserSite() {
      this.loader = true
      const formData = new FormData()
      //   formData.append('_method', this.$route.params.id ? 'PUT' : 'POST')

      //   this.engineerForm.certificates.forEach(cert => {
      //     formData.append("certificates[]", cert);
      //   });

      Object.keys(this.engineerForm.subCertificates).forEach(key => {
        this.engineerForm.subCertificates[key].forEach(subCert => {
          formData.append('certificates[]', subCert)
        })
      })

      axios
        .post(`/engineer/${this.$route.params.id}/certificate`, formData)
        .then(res => {
          if (res.status === 200 || res.status === 201) {
            this.$router.push({ name: 'engineers' })
            this.$toasted.show('Updated Successfully', {
              position: 'top-center',
              duration: 3000,
            })
          }
        })
        .catch(error => {
          if (error.response.status) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `${error.response.data.message}`,
                icon: 'BellIcon',
                variant: 'error',
              },
            })
          }
        })
        .finally(() => {
          this.loader = false
        })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
